import request from '@/utils/request'

// 爱心企业获取相对信息
export function wishInfomatin(params) {
  return request({
    url: '/v2/api/app/ap_member//wish/member/query/wish/info',
    method: 'get',
    params
  })
}

export default {wishInfomatin }